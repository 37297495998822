<template>
    <div @click="hideFollowModal" style="height:100%">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>招生咨询</a-breadcrumb-item>
            <a-breadcrumb-item>我的线索</a-breadcrumb-item>
        </a-breadcrumb>
        <div :class="radioValue === 1?'clue-board':'main-box'">
            <div class="clearfix table-tools">
                <div class="buttons">
                    <a-form layout='inline'>
                    <a-form-item v-if="canSave">
                        <a-button @click='showEditModal' type="primary" icon="plus" >新增</a-button>
                    </a-form-item>
                    <a-form-item v-if="canExcelimport">
                        <a-button @click="showUploadModal" icon="export">导入</a-button>
                    </a-form-item>
                    <a-form-item v-if="canExcelexport">
                        <a-button @click="toExport" icon="export" :loading="exportLoading">导出</a-button>
                    </a-form-item>
                    <a-form-item>
                        <a-dropdown :visible="showColumns">
                            <a-menu @mouseleave="showColumns = false" slot="overlay">
                                <a-menu-item :key="index" v-for="(item,index) in columns">
                                    <a-checkbox @change="onChangeColspan(item)" :checked="item.show_colspan">{{item.title}}</a-checkbox>
                                </a-menu-item>
                            </a-menu>
                            <a-button @click="showColumns = true"> 选择列 <a-icon type="down" /> </a-button>
                        </a-dropdown>
                    </a-form-item>
                    </a-form>
                </div>
                <div class="search">
                    <a-form layout='inline' v-if="selectedRowKeys.length == 0" @submit="searchList">
                        <!-- <a-form-item>
                            <a-radio-group v-model="radioValue" @change="handleChangeRadio">
                                <a-radio-button :value="2">
                                    <a-tooltip>
                                        <template slot="title">
                                        列表模式
                                        </template>
                                        <img src="@/assets/transverse.svg" alt="">
                                    </a-tooltip>
                                </a-radio-button>
                                <a-radio-button :value="1" disabled>
                                    <a-tooltip>
                                        <template slot="title">
                                        看板模式
                                        </template>
                                        <img src="@/assets/vertical.svg" alt="">
                                    </a-tooltip>
                                </a-radio-button>
                            </a-radio-group>
                        </a-form-item> -->

                        <a-form-item>
                            <a-radio-group :value="searchParams.search.is_star">
                                <a-radio-button :value="1" @click="handleChangeRadioStar(1)">
                                    <a-tooltip>
                                        <template slot="title">
                                        只看关注
                                        </template>
                                        <a-icon v-if="searchParams.search.is_star" type="star" theme="twoTone" two-tone-color="#f6d553" />
                                        <a-icon v-else type="star" theme="twoTone" two-tone-color="#d9d9d9" />
                                    </a-tooltip>
                                </a-radio-button>
                            </a-radio-group>
                        </a-form-item>

                        <a-form-item>
                            <a-tooltip placement="topLeft" >
                            <a-select :value='searchParams.search.is_sub' @change='handleIsSub' style="width: 100px">
                                <a-select-option :value="0">我的</a-select-option>
                                <a-select-option :value="1">我下属的</a-select-option>
                            </a-select>
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-tooltip placement="topLeft" >
                            <a-select :value='searchParams.search.sub_status' @change='handleSubStatus' style="width: 120px">
                                <a-select-option :value="1">待转化线索</a-select-option>
                                <a-select-option :value="2">已转化线索</a-select-option>
                                <a-select-option :value="4">待转线索池</a-select-option>
                                <a-select-option :value="3">全部线索</a-select-option>
                            </a-select>
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-input v-model='searchParams.search.keyword'  style="width: 160px" allowClear placeholder="线索名称/手机号"></a-input>
                        </a-form-item>
                        <a-form-item>
                            <a-select v-model='searchParams.search.studio_id' style="width: 200px" mode="multiple" :maxTagCount="1" :maxTagTextLength='5'  allowClear showSearch placeholder="请选择校区" :filterOption="filterOption">
                            <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item>
                            <a-tree-select
                                style="width:200px"
                                v-model="searchParams.search.tag_id"
                                :tree-data="tags"
                                tree-checkable
                                allowClear
                                treeNodeFilterProp="title"
                                :maxTagCount='1'
                                :maxTagTextLength='5'
                                :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                                :show-checked-strategy="SHOW_ALL"
                                placeholder="请选择标签"
                            />
                        </a-form-item>
                        <!-- <a-form-item>
                            <a-input v-model.trim="searchParams.search.cellphone"  allowClear placeholder="联系电话" style="width: 140px"></a-input>
                        </a-form-item> -->
                        <a-form-item v-if="canIndex">
                            <a-button @click="searchList" html-type="submit" :loading="loading" type="primary" >搜索</a-button>
                        </a-form-item>

                        <a-form-item>
                            <a-popover  placement="bottom" trigger="click" v-model="visible">
                            <template slot="content">
                                <div class="more-search" style="width:740px">
                                <a-form layout='inline'>
                                    <a-form-item class="block-line" label="来源渠道">
                                        <a-tree-select
                                            v-model="searchParams.search.channel_id"
                                            :tree-data="channels"
                                            tree-checkable
                                            allowClear
                                            treeNodeFilterProp="title"
                                            :maxTagCount='1'
                                            :maxTagTextLength='5'
                                            :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                                            :show-checked-strategy="SHOW_ALL"
                                            placeholder="请选择来源渠道"
                                            @change="changeBadge"
                                        />
                                    </a-form-item>
                                    <a-form-item class="block-line" label="意向科目">
                                        <a-select v-model='searchParams.search.course_cate_id' @change="changeBadge" allowClear showSearch placeholder="请选择意向科目" :filterOption="filterOption">
                                        <a-select-option v-for="(d, index) of classCategorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="意向程度">
                                        <a-select v-model='searchParams.search.intention_level' @change="changeBadge" allowClear placeholder="请选择意向程度">
                                        <a-select-option v-for="(d, index) of intentionLevels" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="跟进状态">
                                        <a-select mode="multiple" v-model='searchParams.search.task_step' @change="changeBadge" allowClear placeholder="请选择跟进状态">
                                        <a-select-option v-for="(d, index) of dealStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="到访状态">
                                        <a-select v-model='searchParams.search.is_attend' @change="changeBadge" allowClear placeholder="请选择到访状态">
                                        <a-select-option v-for="(d, index) of isAttends" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="课程顾问">
                                        <a-select mode="multiple" v-model='searchParams.search.course_consultant_id' @change="changeBadge" allowClear showSearch placeholder="请选择课程顾问" :filterOption="filterOption">
                                        <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['course_consultant_id']">{{ d['filter_name'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="添加人">
                                        <a-select mode="multiple" :maxTagCount="1" :maxTagTextLength='5' @change="changeBadge" v-model='searchParams.search.created_by' allowClear showSearch @search="handleSearch" placeholder="请选择添加人" :filterOption="filterOption">
                                        <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <!-- <a-form-item class="block-line" label="在读年级">
                                        <a-select mode="multiple" v-model='searchParams.search.grade' allowClear showSearch placeholder="请选择在读年级" :filterOption="filterOption">
                                        <a-select-option v-for="(d, index) of grades" :key="index" :value="d['value']">{{ d['filter_name'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item> -->
                                    <a-form-item class="block-line" label="诺访时间">
                                            <a-range-picker
                                            :ranges="rangesData"
                                            v-model='searchDatas.promise'
                                            allowClear
                                            @change="(val,time)=>handleTime(val,time,'promise')" />
                                    </a-form-item>
                                    <a-form-item class="block-line" label="回访时间">
                                            <a-range-picker
                                            :ranges="rangesData"
                                            v-model='searchDatas.next'
                                            allowClear
                                            @change="(val,time)=>handleTime(val,time,'next')" />
                                    </a-form-item>
                                    <a-form-item class="block-line" label="分配时间">
                                            <a-range-picker
                                            :ranges="rangesData"
                                            v-model='searchDatas.distribute'
                                            allowClear
                                            @change="(val,time)=>handleTime(val,time,'distribute')" />
                                    </a-form-item>

                                    <a-form-item class="block-line" label="到访时间">
                                            <a-range-picker
                                            :ranges="rangesData"
                                            v-model='searchDatas.attend'
                                            allowClear
                                            @change="(val,time)=>handleTime(val,time,'attend')" />
                                    </a-form-item>
                                    <a-form-item class="block-line" label="最后跟进">
                                            <a-range-picker
                                            :ranges="rangesData"
                                            v-model='searchDatas.update'
                                            allowClear
                                            @change="(val,time)=>handleTime(val,time,'update')" />
                                    </a-form-item>
                                    <a-form-item class="block-line" label="添加时间">
                                            <a-range-picker
                                            :ranges="rangesData"
                                            v-model='searchDatas.add'
                                            allowClear
                                            @change="(val,time)=>handleTime(val,time,'add')" />
                                    </a-form-item>

                                    <a-form-item class="block-line" label="首次跟进">
                                            <a-range-picker
                                            :ranges="rangesData"
                                            v-model='searchDatas.first'
                                            allowClear
                                            @change="(val,time)=>handleTime(val,time,'first')" />
                                    </a-form-item>
                                </a-form>
                                <div class="more-search__buttons">
                                    <a-button type="dashed" @click="closeSearch">取消</a-button>
                                    <a-button @click="reset">重置</a-button>
                                    <a-button v-if="canIndex" type="primary" @click="searchList">搜索</a-button>
                                </div>
                                </div>
                            </template>
                                <a-badge :count="badgeCount">
                                    <a-button>更多<a-icon type="down" /></a-button>
                                </a-badge>
                            </a-popover>
                        </a-form-item>
                    </a-form>
                    <a-form layout='inline' v-else>
                        <a-form-item>
                            已选择<span>{{selectedRowKeys.length}}</span>项
                        </a-form-item>
                        <a-form-item>
                            <a-divider type="vertical" />
                        </a-form-item>
                        <a-form-item v-if="canTransfer">
                            <a-button @click="showTransfer" icon="apartment">批量转移</a-button>
                        </a-form-item>
                        <a-form-item v-if="canTransform">
                            <a-button @click="toReceive" icon="usergroup-add">转化为客户</a-button>
                        </a-form-item>
                        <a-form-item v-if="canTransfer">
                            <a-button @click="toRenew" icon="wechat">推送提醒</a-button>
                        </a-form-item>
                        <a-form-item v-if="canDelete">
                            <a-button @click="toDel" icon="delete">批量删除</a-button>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
            <div v-if="radioValue === 2" @click.stop class="table table-td">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="middle" :pagination="false" :bordered='false' rowKey="task_id"
                :selection="{key: 'task_id'}"
                :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps:record=>({props:{disabled:record.is_transform == 1,name:record.cule_name}})}"
                :columns="columnsList" :dataSource="list" :scroll="{ x: 1700 }" @change="handleChange" :customRow="clickRow" :rowClassName="rowClassName">
                    <template slot="index" slot-scope="text, record , index">
                        <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                    </template>

                    <template slot="star" slot-scope="text, record">
                        <a-icon @click.stop="toTop(record)" v-if="record.is_star == 1" type="star" theme="twoTone" two-tone-color="#f6d553" />
                    </template>

                    <template slot="tags" slot-scope="text,record">
                        <a-tag class="tag" style="margin:0 5px" v-for="(it,j) in record.tags" :key="j">{{it}}</a-tag>
                        <!-- <span style='cursor:pointer' @click="e => e.preventDefault()" v-if='record.tags.length==0'>--</span> -->
                    </template>

                    <template slot="name" slot-scope="text,record">
                        <div>
                            <div>
                                <a style="display:block;max-width:75px;overflow:hidden;text-overflow:ellipsis;white-space: nowrap;">
                                <!-- <a-icon type="check-circle" v-if="record.is_transform"/> -->
                                {{ text }}
                                </a>
                            </div>

                            <span style="font-size:10px;color: rgba(69, 90, 100, 0.6);display:block;max-width:75px;overflow:hidden;text-overflow:ellipsis;white-space: nowrap;"></span>
                                ({{record.gender}}{{record.year?','+record.year+'岁':''}})
                            </span>

                            <div v-if="record.is_transform">
                                <img style="width:30px;position:absolute;right:0;top:0" src="@/assets/customtag.png" alt="">
                            </div>
                        </div>
                    </template>

                     <template slot="cellphone" slot-scope="text,record">
                         <div @click.stop v-clipboard:copy="text" v-clipboard:success="onCopy" v-clipboard:error="onError">
                             {{record.cellphone}}
                             <a-icon type="copy" :style="{ fontSize: '12px'}" />
                         </div>
                         <a-popover :title="record.source_channel" v-if="record.tips">
                            <template slot="content">
                                <div style="white-space:pre-line;">{{record.tips}}</div>
                            </template>
                            <a-icon type="bulb" theme="twoTone" two-tone-color="#faad14" />
                        </a-popover>

                         <span style="font-size:10px;color: rgba(69, 90, 100, 0.6);" v-if="record.cellphone_geo">
                             ({{record.cellphone_geo.province}},{{record.cellphone_geo.op}})
                         </span>
                    </template>

                    <template slot="source_channel" slot-scope="text,record">
                         <div style="display:flex;align-items:center">
                            <a-badge v-if="record.channel_level == 1" status="default" />
                            <a-badge v-if="record.channel_level == 3" status="warning" />
                            <a-badge v-if="record.channel_level == 5" status="error" />
                            <a-tooltip>
                                <template slot="title">
                                {{text}}
                                </template>
                                <div style="max-width:130px;overflow:hidden;text-overflow:ellipsis;white-space: nowrap;">{{text}}</div>
                            </a-tooltip>
                         </div>
                    </template>

                    <template slot="time" slot-scope="text">
                         <span v-if="text">
                             <a-badge :color="timeHandle(moment(text).diff(moment(),'hour')).color" :text="timeHandle(moment(text).diff(moment(),'hour')).text" />
                         </span>
                         <span v-else>--</span>
                    </template>

                    <template slot="promise" slot-scope="text">
                         <span v-if="text">{{moment(text).format("YYYY") == moment().format("YYYY")?moment(text).format("M月D日 HH:mm"):moment(text).format("YYYY年M月D日 HH:mm")}}</span>
                         <span v-else>--</span>
                    </template>

                    <template slot="null_txt" slot-scope="text">
                         <span v-if="text" class="short_content">
                            <a-tooltip>
                                <template slot="title">
                                {{text}}
                                </template>
                                {{text}}
                            </a-tooltip>
                         </span>
                         <span v-else>--</span>
                    </template>

                    <template slot="is_transform" slot-scope="text">
                        <a-tag v-if="text == 1" color="#00cca2">已转移</a-tag>
                        <a-tag v-else color="red">未转移</a-tag>
                    </template>

                    <template slot="deal_status" slot-scope="text,record">
                        <a-tag color="green" v-if="text==1">
                            在读学员
                        </a-tag>
                        <a-tag color="orange" v-if="text==0">
                            潜在学员
                        </a-tag>    
                    </template>

                    <template slot="task_step" slot-scope="text,record">
                        
                        <div @click.stop>
                            <a-dropdown placement="bottomCenter" :trigger="['click']">
                                <a-tag style='cursor:pointer' v-for="item in dealStatus" :key='item.value' :color="item.color"  v-if="text==item.value">
                                    {{item.label}}
                                </a-tag>
                                <a-menu slot="overlay">
                                    <a-menu-item @click='handleDealStatus(item,record)' v-for="item in dealStatus" :key='item.value'>
                                        <a-tag style='cursor:pointer' :color="item.color">
                                            {{item.label}}
                                        </a-tag>
                                        <a-icon type="check" :style="{color: '#00cca2' }" v-if="text==item.value"/>
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                        </div>
                        
                    </template>

                    <template slot="action" slot-scope="text,record">
                        <a href="javascript:;" @click="showFollowModal($event, record)">跟进</a>
                    </template>
                </a-table>
            </div>
            <div v-if="radioValue === 2" class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
            <div v-if="radioValue === 1" class="clue-board-box">
                <board :searchData="searchParams"/>
            </div>
        </div>
        <followModal :authType="authType" :item="modalData" :search='searchParams.search' :leadsId='leadsId' :isTab='isTab' v-if="followVisible && canRead"/>
        <uploadModal v-if="isUploadModal" isTab='0'/>
        <!-- <distribution v-if="isDistribution" isTab='0' :customerId='selectedRowKeys'/> -->
        <editModal v-if="isEditModal" :isEdit='0' />
        <exportModal v-if="exportLoading" :exportData='exportData' :exportInfo='exportInfo' />
        <transfer :isTab='0' :isAll='isAll' :item='selectedRowKeys' :search='modalData' v-if="isTransfer"/>
    </div>
</template>
<script>
const columns = [
  { title: '#', width:'35px', scopedSlots: { customRender: 'index' }, key: 'task_id',fixed: 'left', show_colspan: true},
  {title: '', width:'20px', dataIndex: 'cule_name', key: 'is_sta' ,fixed: 'left',scopedSlots: { customRender: 'star' }, show_colspan: true},
  {title: '线索名称',width:'100px', dataIndex: 'cule_name', key: 'cule_name' ,fixed: 'left', scopedSlots: { customRender: 'name' }, show_colspan: true},
//   {title: '性别', width:'45px', dataIndex: 'gender', key: 'gender',align:"center" ,fixed: 'left'},
//   {title: '年龄', width:'45px', dataIndex: 'year', key: 'year',align:"center" ,fixed: 'left'},
  {title: '联系电话', width:'120px',dataIndex: 'cellphone', key: 'cellphone' ,fixed: 'left',scopedSlots: { customRender: 'cellphone' }, show_colspan: true},
  {title: '跟进状态', width:'100px',dataIndex: 'task_step', key: 'task_step' ,scopedSlots: { customRender: 'task_step' },show_sorter: true,align:"left", show_colspan: true},
  {title: '最后跟进', dataIndex: 'recent_contact',ellipsis: true, key: 'recent_contact' ,show_sorter: true, show_colspan: true,scopedSlots: { customRender: 'null_txt' }},

  {title: '线索标签', width:'100px',ellipsis: true, dataIndex: 'tags', key: 'tags' , scopedSlots: { customRender: 'tags' }},

  {title: '跟进内容', dataIndex: 'last_follow', key: 'last_follow' , show_colspan: true,scopedSlots: { customRender: 'null_txt' }},
  {title: '回访时间', dataIndex: 'next_time', key: 'next_time' ,show_sorter: true, scopedSlots: { customRender: 'time' },align:'left', show_colspan: true},
  {title: '来源渠道', dataIndex: 'source_channel',ellipsis: true, scopedSlots: { customRender: 'source_channel' }, key: 'client_channel.level',show_sorter: true, show_colspan: true},
  {title: '归属校区', dataIndex: 'studio_id',ellipsis: true,  key: 'studio_id' ,show_sorter: true, show_colspan: true},
  {title: '联系次数', width:"100px", dataIndex: 'contact_count', key: 'contact_count' ,show_sorter: true,align:"center", show_colspan: true}, 
  {title: '负责人', width:"80px",dataIndex: 'distribute_to', key: '13' ,ellipsis: true,show_sorter: true, show_colspan: true},
//   {title: '转移状态', dataIndex: 'is_transform', key: 'is_transform', scopedSlots: { customRender: 'is_transform' }},
  {title: '备注', dataIndex: 'remark', key: '9' ,ellipsis: true, show_colspan: true},
  {title: '添加人', width:"80px", dataIndex: 'created_by', key: '10' ,show_sorter: true,ellipsis: true, show_colspan: true},
  {title: '添加时间', dataIndex: 'created_at',ellipsis: true, key: 'created_at' ,show_sorter: true , show_colspan: true},
  {title: '首次跟进', dataIndex: 'first_contact',ellipsis: true, key: 'first_contact' ,show_sorter: true , show_colspan: true},
  {title: '分配时间', dataIndex: 'assigned_at',ellipsis: true, key: 'assigned_at' ,show_sorter: true , show_colspan: true},
//   { title: '操作', key: 'operation', fixed: 'right', width:80, scopedSlots: { customRender: 'action' }}
]

import uploadModal from '@/views/clientresource/modal/uploadModal'
import followModal from '@/views/clientresource/modal/followModal/index'
import distribution from '@/views/clientresource/seas/distribution'
import exportModal from '@/views/export/index'
import tableMixins from '@/common/mixins/table'
import ranges from "@/common/mixins/ranges"
import authority from '@/common/mixins/authority'
import editModal from './editModal'
import transfer from './transfer'
import board from './board'
import { timeHandle } from '@/utils/timeFormat'
import moment from 'moment'
import C_ITEMS from '@/utils/items'

export default {
    name:'clue',
    provide() {
        return {
            parent: this
        }
    },
    mixins: [ tableMixins , authority , ranges ],
    components:{
        board,
        editModal,
        transfer,
        exportModal,
        uploadModal,
        followModal,
        distribution
    },
    data(){
        return{
            loading: false,
            visible: false,
            showColumns:false,
            list:[],
            tags: [],
            badgeCount:0,
            radioValue:2,
            starValue:'',
            selectedRowKeys:[],
            pageSizeOptions: ['10', '20', '30', '40', '100'],
            current: 1,
            columns,
            columnsList:[],
            pageParams: {
                currentPage: 1,
                pageCount: 50,
                perPage: 10,
                totalCount: 0
            },
            searchParams: {
                "page": 1,
                "per-page": 10,
                "search": {
                    is_sub:0,
                    sub_status:1
                },
                "sort": ''
            },
            searchDatas:{},
            modalData: {},
            isEditModal:false,
            isTransfer:false,
            isUploadModal:false,
            followVisible:false,
            isDistribution:false,
            exportLoading:false,
            leadsId:'',
            clickId:'',
            grades:[],
            studios:[],
            channels:[],
            channelsId:[],
            expandedKeys:[],
            consultants:[],
            staffList:[],
            classCategorys:[],
            studioId:undefined,
            authType:['crm','leads'],
            isAttends: C_ITEMS.isAttendsF,
            intentionLevels:C_ITEMS.intentionLevelsF,
            dealStatus:C_ITEMS.dealStatus,
            paramsData:{
                start_date:'',
                end_date:'',
            },
            exportData:{},
            exportInfo:{
                url:'cLeadsExportAction',
                name:'线索列表导出'
            },
            isAll:0,
            isTab:'0',
        }
    },
    beforeCreate () {
        if(!this.$ls.get('is_sub')){
            this.$ls.set('is_sub', 0)
        }
        if(!this.$ls.get('sub_status')){
            this.$ls.set('sub_status', 1)
        }
    },
    created(){
        // this.$ls.remove('leads_columnsList')//该行代码2021-11-3可删除
        if(this.$ls.get('leads_columnsList') && 0){
            this.columns = this.$ls.get('leads_columnsList')
            this.columns.forEach(item=>{
                if(item.show_colspan){
                    this.columnsList.push(item)
                }
            })
        }else{
            this.columnsList = this.columns
            this.$ls.set('leads_columnsList', this.columns)
        }
        this.columnsList.forEach(item=>{
            if(item.show_sorter){
                item.sorter = ()=>{}
                item.sortDirections= ['descend', 'ascend'];
            }
        })

        // this.getGrade()
        this.getStudio()
        this.getStaff()
        this.getSourceChannel()
        this.getConsultant()
        this.getClassCategory()
        this.getTag()
    },
    methods:{
        onCopy(){
            this.$store.dispatch('toolsClickAction', {data: {event_type:'event_clue_cellphone_click'}})
            this.$message.success('已复制到剪切板!')
        },
        onError(){
            this.$message.error('操作失败!')
        },
        moment,
        timeHandle,
        filterOption(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        async getTag(){
            let res = await this.$store.dispatch('searchGetTagsAction', {})
            this.tags = res.data
        },
        async getList() {
            if(!this.canIndex){
                this.$message.warning('您暂无权限!!')
                return false
            }
            if(this.$route.query.start_date){
                this.searchParams.search.add_start_date = this.$route.query.start_date
                this.searchParams.search.add_end_date = this.$route.query.end_date
                this.searchDatas.add = [moment(this.$route.query.start_date),moment(this.$route.query.end_date)]
            }
            this.loading = true
            this.searchParams.search.is_sub = this.$ls.get('is_sub')
            this.searchParams.search.sub_status = this.$ls.get('sub_status')
            let res = await this.$store.dispatch('cLeadsAction', {data: this.searchParams})
            this.list = res.items
            this.pageParams = res._meta
            this.loading = false
        },
        async getGrade(){
            let res = await this.$store.dispatch('searchGetGradeTypeAction', {})
            this.grades = res.data
        },
        async getStudio() {
            let res = await this.$store.dispatch('searchBelongStudioAction', {})
            this.studios = res.data
        },
        async getSourceChannel(id) {
            let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
            this.channels = res.data
        },
        async getConsultant() {
            let res = await this.$store.dispatch('searchConsultantAction', {})
            this.consultants = res.data
        },
        async getClassCategory() {
            let res = await this.$store.dispatch('searchCourseTypeAction', {})
            this.classCategorys = res.data
        },
        async getStaff(val){
            let obj = {q:val}
            let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
            this.staffList = res.data
        },
        handleSearch(val){
            this.getStaff(val)
        },
        showUploadModal(){
            this.isUploadModal = true
        },
        hideUploadModal(){
            this.isUploadModal = false
        },
        clickRow(record, index) {
            return {
                on: {
                    click: () => {
                        if(record.is_transform){
                            this.isTab = '1'
                        }else{
                            this.isTab = '0'
                        }
                        this.clickId = record.leads_id
                        this.showFollowModal(null, record)
                    }
                }
            }
        },
        rowClassName(record, index) {
            let className = ""
            if (record.leads_id == this.clickId) {
                className = "rowActive";
                this.$emit('change', this.clickId)
            }
            return className;
        },
        showFollowModal(e, item) {
            e && e.stopPropagation()
            this.modalData = item
            this.leadsId = item.leads_id
            this.followVisible = true
            return 
        },
        hideFollowModal(type) {
            if (type === 1) {
                this.getList()
            }
            this.followVisible = false
        },
        handleKeyup(e){
            console.log(e)
        },
        handleChange(pagination, filters, sorter){
            if(sorter.order){
                console.log(sorter);
                if(sorter.order === "ascend"){
                    this.searchParams['sort'] = `${sorter.column.key}`
                }else{
                    this.searchParams['sort'] = `-${sorter.column.key}`
                }
                }else{
                this.searchParams['sort'] = ''
                }
            this.getList()
        },
        showTransfer(){
            let that = this
            let search = {...this.searchParams.search}
            delete search.cellphone
            delete search.cule_name
            delete search.is_sub
            delete search.sub_status
            this.modalData = this.searchParams.search
            let isHave = false
            for (let key in search) {
                if(search[key] !== "" && search[key] !== undefined){
                    if(search[key].length == 0){
                        isHave = false
                    }else{
                        isHave = true
                        break
                    }
                }
            }
            if(this.selectedRowKeys.length >= this.pageParams.perPage && isHave && 0){
                this.$confirm({
                    title: `是否转移该条件下所有的线索？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        that.isAll = 1
                        that.isTransfer = true
                    },
                    onCancel(){
                        that.isAll = 0
                        that.isTransfer = true
                    }
                })
            }else{
                this.isTransfer = true
            }
            
        },
        hideTransfer(num){
            if(num){
                this.selectedRowKeys = []
                this.getList()
            }
            this.isTransfer = false
        },
        toRenew(){
            let that = this
            this.loading = true
            return new Promise(async (resolve, reject) => {
                await that.$store.dispatch('cLeadsRenewAction', { task_id: that.selectedRowKeys })
                .then(res=>{
                    that.$message.success('操作成功~')
                    that.selectedRowKeys = []
                    that.getList()
                    resolve(res)
                    this.loading = false
                })
                .catch(err=>{
                    reject(err)
                    this.loading = false
                })
            }).catch(error => console.log(error))
            
            // this.$confirm({
            //     title: `您确定renew这些线索？`,
            //     okText: '确定',
            //     okType: 'danger',
            //     cancelText: '取消',
            //     zIndex: 4200,
            //     onOk() {
            //     return new Promise(async (resolve, reject) => {
            //         await that.$store.dispatch('cLeadsRenewAction', { task_id: that.selectedRowKeys })
            //         .then(res=>{
            //             that.$message.success('操作成功~')
            //             that.selectedRowKeys = []
            //             that.getList()
            //             resolve(res)
            //         })
            //         .catch(err=>{
            //             reject(err)
            //         })
            //     }).catch(error => console.log(error))
            //     }
            // })
        },
        toReceive(){
            let that = this
            this.$confirm({
                title: `确定将这些线索转换为客户吗?`,
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                return new Promise(async (resolve, reject) => {
                    let res = await that.$store.dispatch('cLeadsTransformAction', { task_id: that.selectedRowKeys })
                    if (res) {
                        that.selectedRowKeys = []
                        that.$message.success('操作成功!')
                        that.getList()
                        resolve(res)
                    }
                }).catch(error => console.log(error))
                }
            })
        },
        showEditModal(){
            this.isEditModal = true
        },
        hideEditModal(val){
            if(val){
                this.getList()
            }
            this.isEditModal = false
        },
        async toExport(){
            let exportData = {
                search:this.searchParams.search,
                sort:this.searchParams.sort
            }
            exportData.search.task_id = this.selectedRowKeys
            this.exportData = exportData
            this.exportLoading = true
        },
        hideExportModal(){
            this.searchParams.search.task_id = []
            this.selectedRowKeys = []
            this.exportLoading = false
        },
        toDel(){
            let that = this
            this.$confirm({
                title: '您确定删除这些线索吗?',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                zIndex: 4200,
                onOk() {
                return new Promise(async (resolve, reject) => {
                    let res = await that.$store.dispatch('cLeadsDeleteAction', {task_id:that.selectedRowKeys})
                    if (res) {
                    that.selectedRowKeys = []
                    that.$message.success('操作成功!')
                    that.getList()
                    resolve(res)
                    }
                }).catch(error => console.log(error))
                }
            })
        },
        async toTop(item){
            let that = this
            this.$confirm({
                title: '您确定取消线索置顶??',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                zIndex: 4200,
                onOk() {
                return new Promise(async (resolve, reject) => {
                    await that.$store.dispatch('cLeadsStarAction', {task_id:item.task_id})
                    .then(res=>{
                        that.$message.success('操作成功~')
                        that.getList()
                        resolve(res)
                    })
                    .catch(err=>{
                        resolve(err)
                    })
                }).catch(error => console.log(error))
                }
            })
        },
        handleIsSub(e){
            this.$ls.set('is_sub', e)
            this.searchParams.search.is_sub = e
            this.changeBadge()
        },
        handleSubStatus(e){
            this.$ls.set('sub_status', e)
            this.searchParams.search.sub_status = e
        },
        reset() {
            this.badgeCount = 0
            this.channelsId = null
            this.searchDatas = {}
            this.searchParams.search = {is_sub:0}
        },
        closeSearch() {
            this.visible = false
        },
        handleTime(val,time,name){
            this.searchDatas[name] = val
            this.$forceUpdate()
            let start_date = name + '_start_date'
            let end_date = name + '_end_date'
            this.searchParams.search[start_date] = time[0]
            this.searchParams.search[end_date] = time[1]
            this.changeBadge()
        },
        handleChangeRadio(e){
            if(e.target.value === 2){
                this.searchList()
            }
        },
        handleChangeRadioStar(e){
            if(this.searchParams.search.is_star == e){
                this.searchParams.search.is_star = undefined
            }else{
                this.searchParams.search.is_star = e
            }
            this.searchList()
        },
        changeBadge(){
            let obj = {...this.searchParams.search}
            delete obj.keyword
            delete obj.is_sub
            delete obj.sub_status
            delete obj.studio_id
            var _toString = Object.prototype.toString
            let sum = 0
            for(let i in obj){
                console.log(_toString.call(obj[i]).slice(8, -1))
                if(_toString.call(obj[i]).slice(8, -1) == 'Array' && obj[i].length >0){
                    sum++
                }
                if(_toString.call(obj[i]).slice(8, -1) == 'Number'){
                    sum++
                }
                if(_toString.call(obj[i]).slice(8, -1) == 'String' && obj[i]){
                    if(i == 'promise_start_date' || i == 'add_start_date' || i == 'attend_start_date' || i == 'next_start_date' || i == 'update_start_date' || i == 'distribute_start_date'){
                        sum++
                    }
                }
            }
            this.badgeCount = sum
        },
        async handleDealStatus(item,record){
            let obj = {}
            if(record.is_transform){
                obj = {
                    customer_id:record.customer_id,
                    task_step:item.value
                }
            }else{
                obj = {
                    task_id:record.task_id,
                    task_step:item.value
                }
            }
            await this.$store.dispatch(record.is_transform?'cCustomerTaskUpdateAction':'cLeadsTaskUpdateAction', obj)
                .then(res=>{
                    this.$message.success('操作成功~')
                    this.getList()
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                })
        },
        onChangeColspan(item){
            item.show_colspan = !item.show_colspan
            this.$ls.set('leads_columnsList', this.columns)
            let arr = []
            this.columns.forEach(item=>{
                if(item.show_colspan){
                    arr.push(item)
                }
            })
            this.columnsList = arr
        }
    }
}
</script>
<style lang="scss">
    .tag{
        color: #999 !important;
        border-color: #f5f7fa !important;
        border-bottom-left-radius: 11px;
        border-top-left-radius: 11px;
        border-top-right-radius: 11px;
        border-bottom-right-radius: 11px;
        font-size:10px;
    }
  .rowActive{
    background:#e6fff6
  }
  .table-td{
      td{
          position: relative;
      }
  }
  .short_content{
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    display: -moz-box;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;

    overflow-wrap: break-word;
    word-break: break-all;
    white-space: normal;
    overflow: hidden;
  }
  .clue-board{
      height: calc(100% - 61px);
      padding: 15px;
      &-box{
        height: calc(100% - 50px);
      }
  }
</style>