<template>
    <div class="board">
        <ul>
            <li v-for="(item,index) in list" :key="item.value" class="board-item">
                <vue-element-loading :active="item.loading" color="#00cca2" spinner="spinner"/>
                <div class="board-item-nav">
                    <h1>{{item.label}}</h1>
                    <div>{{item.arr.length}}个客户</div>
                </div>
                <div @mouseover="hadleOver(index,j)" @mouseout="handleOut"  style="margin-bottom:10px;" v-for="(it,j) in item.arr" :key="it.task_id">
                    <a-card hoverable class="board-card" style="width: 100%">
                        <div class="board-card-nav board-mb">
                            <div class="board-card-info">
                                <!-- <a-avatar shape="square" :size="46" src="https://wx.qlogo.cn/mmhead/xXKmia0OG9aBCLoMdeqhPyT30ZR3b5wxsOAoqWL4rDDc/64" icon="user" /> -->
                                <a-avatar shape="square" :size="46" icon="user" />
                                <div class="board-card-name">
                                    <div>{{it.cule_name}}</div>
                                    <div>@微信</div>
                                </div>
                            </div>
                            <div style="text-align:center">
                                <div>0</div>
                                <div>评分</div>
                            </div>
                        </div>
                        <div class="board-mb">
                            上次跟进: {{it.created_at}}
                        </div>
                        <div class="board-mb">
                            课程顾问: {{it.created_by}}
                        </div>
                        <div>
                            <a-tag class="board-mb" v-for="item in 7" :key="item">Tag {{item}}</a-tag>
                        </div>
                        <div class="board-ellipsis">
                            <a-dropdown>
                                <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                    <a-icon type="ellipsis" />
                                </a>
                                <a-menu slot="overlay">
                                <a-menu-item>
                                    <a href="javascript:;">1st menu item</a>
                                </a-menu-item>
                                <a-menu-item>
                                    <a href="javascript:;">2nd menu item</a>
                                </a-menu-item>
                                <a-menu-item>
                                    <a href="javascript:;">3rd menu item</a>
                                </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                        </div>
                    </a-card>
                </div>
            </li>
        </ul>

    </div>
</template>

<script>
    export default {
        name:"board",
        data() {
            return {
                loading:false,
                list: [
                    { label: '未跟进', value: 0 , arr:[], page:1 ,loading:false},
                    { label: '跟进中', value: 1 , arr:[], page:1 ,loading:false},
                    { label: '已预约', value: 2 , arr:[], page:1 ,loading:false},
                    { label: '已到访', value: 3 , arr:[], page:1 ,loading:false},
                    { label: '已成单', value: 4 , arr:[], page:1 ,loading:false},
                    { label: '暂搁', value: 5 , arr:[], page:1 ,loading:false},
                    { label: '未接通', value: 6 , arr:[], page:1 ,loading:false},
                    { label: '无效', value: 10 , arr:[], page:1 ,loading:false},
                    { label: '长期跟进', value: 15 , arr:[], page:1 ,loading:false},
                ],
                hoverIndex:[-1,-1],
            }
        },
        props:{
            searchData:Object
        },
        created () {
            this.list.forEach(item=>{
                this.getList(item)
            })
        },
        methods: {
            async getList(item) {
                item.loading = true
                let obj = {
                    task_step:item.value,
                    'page':item.page,
                    "per-page": 30,
                    search:this.searchData.search
                }
                let res = await this.$store.dispatch('cFollowAction', {data:obj})
                item.arr = res.items
                item.loading = false
            },
            hadleOver(index,j){
                this.hoverIndex = [index,j]
            },
            handleOut(){
                this.hoverIndex = [-1,-1]
            }

        },
    }
</script>

<style lang="scss">
    .board{
        width: 100%;
        height: 100%;
        ul{
            list-style: none;
            width: 100%;
            overflow: auto;
            margin-bottom:initial;
            padding-inline-start:0px;
            display: flex;
            height: 100%;
        }
        &-mb{
            margin-bottom: 5px;
        }
        &-item{
            height: 100%;
            background: #fff;
            border-radius: 2px;
            margin-right: 10px;
            flex-grow:0;
            flex-shrink: 0;
            flex-basis: 295px;
            box-shadow: 0 1px 3px 0 rgba(0,0,0,.05);
            padding: 10px;
            &-nav{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
                h1{
                    margin-bottom: 0;
                }
            }
        }
        &-card{
            position: relative;
            .ant-card-body{
                padding: 10px;
            }
            &-nav{
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            &-info{
                display: flex;
                align-items: center;
            }
            &-name{
                margin-left: 10px;
            }
        }
        &-ellipsis{
            position: absolute;
            bottom: 10px;
            right: 10px;
        }
    }
</style>